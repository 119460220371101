import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MetaPagination } from '@app/models/meta-pagination';
import { ShopAffiliationListDetail } from '@app/models/shop-affiliation-list';
import { RequestService } from '@app/services/request.service';
import { ShopAffiliantionService } from '@app/services/shop-affiliation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import * as XLSX from 'xlsx';
import { Location } from '@angular/common';


@Component({
  selector: 'app-shop-list-detail',
  templateUrl: './shop-list-detail.component.html',
  styleUrls: ['./shop-list-detail.component.scss'],
})
export class ShopListDetailComponent implements OnInit {
  @ViewChild('assegnaListaTemplate') private assegnaListaTemplate;
  @ViewChild('programmaVisitaTemplate') private programmaVisitaTemplate;
  @ViewChild('agentFeedBackTemplate') private agentFeedBackTemplate;
  @ViewChild('operatorFeedBackTemplate') private operatorFeedBackTemplate;
  @ViewChild('addDealerTemplate') private addDealerTemplate;

  @ViewChild('popContent') private popContent;

  ordersPagination: MetaPagination;
  shopListDetail: ShopAffiliationListDetail[] = [];
  assegnaListaForm: FormGroup;
  addDealerForm: FormGroup;
  programmaVisitaForm: FormGroup;
  agentFeedBackForm: FormGroup;

  listId: number;
  allChecked = false;
  selectedItems: ShopAffiliationListDetail[] = [];
  visitStatus: string;
  noteAgent: any;
  modalVisitData: any;
  payload = {
    dealer_id: '',
    id: '',
  };
  nameList: string;
  payloadVisit: any;
  payloadFeedBack: any;
  payloadDealer: any;
  // isEditing = {
  //   scheduledId : false,
  //   visitId: false
  // }

  isEditing: boolean = false;

  // editingField: string | null = null;
  editingField: { id: any, field: string } | null = null;



  filter = {
    agent_id: '',
    rag_sociale: '',
    dealer_id: '',
    region_id: '',
    province_id: '',
    city_id: '',
    status: '',
    regione_text: '',
    provincia_text: '',
    citta_text: '',
  };

  popupConfig = {
    type: null,
    key: null,
    items: null,
    id: null,
    isSelect: false,
    popover: null,
    inputValue: null,
  };

  statuses = ShopAffiliationListDetail.visitStatus;

  get affiliationText(): string {
    const affiliationMap = {
      0: 'No',
      1: 'Si',
    };
    return affiliationMap[this.modalVisitData?.visit_data.is_affiliate] || '';
  }

  jsonQuestions: any;

  constructor(
    private route: ActivatedRoute,
    public request: RequestService,
    private spinner: NgxSpinnerService,
    public modalService: NgbModal,
    public formBuilder: FormBuilder,
    private shopAffiliationService: ShopAffiliantionService,
    private _location: Location,
  ) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.listId = params['id'];
      this.loadListDetail(this.listId);
    });

    this.assegnaListaForm = this.formBuilder.group({
      name_list: ['', [Validators.required]],
      description: [''],
    });

    this.addDealerForm = this.formBuilder.group({
      dealer_id: ['', [Validators.required]],
    });

    this.programmaVisitaForm = this.formBuilder.group({
      date: [null, [Validators.required]],
    });

    this.agentFeedBackForm = this.formBuilder.group({
      status: ['', Validators.required],
      note: ['', Validators.required],
    });

  }

  loadListDetail(listId: any) {

    this.spinner.show('table-orders');

    const params = {
      listId: listId,
    };

    if (this.ordersPagination?.currentPage) {
      params['page'] = this.ordersPagination?.currentPage;
    }
    if (this.filter.city_id) {
      params['cityId'] = this.filter.city_id;
    }
    if (this.filter.rag_sociale) {
      params['rag_sociale'] = this.filter.rag_sociale;
    }
    if (this.filter.province_id) {
      params['provinceId'] = this.filter.province_id;
    }
    if (this.filter.region_id) {
      params['regionId'] = this.filter.region_id;
    }
    if (this.filter.agent_id) {
      params['agentId'] = this.filter.agent_id;
    }
    if (this.filter.dealer_id) {
      params['dealer_id'] = this.filter.dealer_id;
    }
    if (this.filter.status) {
      params['status'] = this.filter.status;
    }
    if (this.filter.regione_text) {
      params['regione_text'] = this.filter.regione_text;
    }
    if (this.filter.provincia_text) {
      params['provincia_text'] = this.filter.provincia_text;
    }
    if (this.filter.citta_text) {
      params['citta_text'] = this.filter.citta_text;
    }

    this.shopAffiliationService.getShopAffiliationListDetail(params).subscribe(
      (response: any) => {
        let detail = new ShopAffiliationListDetail().fromArray(response.data);
        this.shopListDetail = detail.map((item: ShopAffiliationListDetail) => {
          item.json_data = JSON.parse(item.json_data);
          return item;
        });
        if (this.shopListDetail.length > 0) {
          this.nameList = this.shopListDetail[0].list_name;
        }
        this.ordersPagination = response._meta;

        this.spinner.hide('table-orders');
      },
      (err) => {
        this.spinner.hide('table-orders');
      },
    );
  }

  showModal() {
    this.modalService.open(this.assegnaListaTemplate, { size: 'md' });
  }

  addDealerLink(item: any) {
    this.modalService.open(this.addDealerTemplate, { size: 'md' });
    this.payloadDealer = item;
  }

  showModalAgent(item: any) {
    this.modalService.open(this.programmaVisitaTemplate, { size: 'md' });
    this.payloadVisit = item;
  }

  showAgentFeedback(item: any) {
    if (item.visitStatus) {
      this.visitStatus = item.status;
    }
    if (item.note) {
      this.noteAgent = item.note;
      this.agentFeedBackForm.get('status').disable();
      this.agentFeedBackForm.get('note').disable();
    } else {
      this.noteAgent = null;
      this.agentFeedBackForm.get('status').enable();
      this.agentFeedBackForm.get('note').enable();
    }
    this.modalService.open(this.agentFeedBackTemplate, { size: 'md' });
    this.payloadFeedBack = item.id;
  }

  showOperatorFeedback(item: any) {
    this.modalVisitData = item;
    this.modalService.open(this.operatorFeedBackTemplate, { scrollable: true, size: 'lg' });
    if (item.nome !== null) {
      if (item.visit_data !== null) {
        const obj = JSON.parse(this.modalVisitData.visit_data.json_data);
        this.jsonQuestions = Object.entries(obj).map(([key, value]) => {
          return {
            key: key,
            value: value,
          };
        });
      }
    }
  }

  dismissModal() {
    this.modalService.dismissAll();
    this.payloadVisit = null;
    this.allChecked = false;
    this.agentFeedBackForm.reset();
    this.modalVisitData = null;
    this.noteAgent = null;
    this.visitStatus = null;
    this.loadListDetail(this.listId);
  }

  setScopeId(event: any): void {
    this.payload.dealer_id = event;
  }

  setDealerScopeId(event: any): void {
    this.payload.dealer_id = event;
  }

  onRegionChange(event: any): void {
    if (event !== undefined) {
      this.filter.region_id = event;
      this.filter.province_id = '';
      this.filter.city_id = '';
    } else {
      this.filter.region_id = '';
      this.filter.province_id = '';
      this.filter.city_id = '';
    }
    this.loadListDetail(this.listId);
    ;
  }

  onProvinceChange(event) {
    if (event !== undefined) {
      this.filter.province_id = event;
      this.filter.city_id = '';
    } else {
      this.filter.province_id = '';
      this.filter.city_id = '';
    }
    this.loadListDetail(this.listId);

  }

  onCityChange(event: any): void {
    if (event !== undefined) {
      this.filter.city_id = event;
    } else {
      this.filter.city_id = '';
    }
    this.loadListDetail(this.listId);
  }

  onAgentChange(event: any): void {
    if (event !== undefined) {
      this.filter.agent_id = event;
    } else {
      this.filter.agent_id = '';
    }
    this.loadListDetail(this.listId);
  }

  onDealerChange(event: any): void {
    if (event !== undefined) {
      this.filter.dealer_id = event;
    } else {
      this.filter.dealer_id = '';
    }
    this.loadListDetail(this.listId);
  }

  onStatusChange(event: any): void {
    if (event !== undefined) {
      this.filter.status = event.key;
    } else {
      this.filter.status = '';
    }
    this.loadListDetail(this.listId);
  }

  toggleSelection(itemId: any): void {
    const index = this.selectedItems.findIndex(item => item.id === itemId);
    if (index === -1) {
      const selectedItem = this.shopListDetail.find(item => item.id === itemId);
      if (selectedItem) {
        this.selectedItems.push(selectedItem);
      }
    } else {
      this.selectedItems.splice(index, 1);
    }
  }

  selectAll(event: any): void {
    const isChecked = event.target.checked;
    this.shopListDetail.forEach(item => item.selected = isChecked);
    this.allChecked = isChecked;
  }


  openPopover(popOver: any, orderId, item, key, selectItems = []) {
    this.popupConfig.inputValue = item;
    this.popupConfig.id = orderId;
    this.popupConfig.key = key;
    this.popupConfig.popover = popOver;
    this.popupConfig.isSelect = selectItems?.length > 0;
    this.popupConfig.items = selectItems;
    popOver.open();
  }

  updateStatus(value) {
    const body = {};
    body[this.popupConfig['key']] = value;
    this.spinner.show('popupSpinner');
    return this.changeOperatorStatus(this.popupConfig.id, body);
  }

  changeOperatorStatus(orderId, body) {
    this.shopAffiliationService.putOperatorStatus(orderId, body).subscribe(
      (response) => {
        this.loadListDetail(this.listId);
        this.spinner.hide('popupSpinner');
      },
      (err) => {
        this.loadListDetail(this.listId);
        this.modalService.dismissAll();
        this.spinner.hide('popupSpinner');
      },
    );
  }


  save() {
    if (this.allChecked) {

      const params = {
        listId: this.listId,
      };

      if (this.filter.city_id) {
        params['cityId'] = this.filter.city_id;
      }
      if (this.filter.rag_sociale) {
        params['rag_sociale'] = this.filter.rag_sociale;
      }
      if (this.filter.province_id) {
        params['provinceId'] = this.filter.province_id;
      }
      if (this.filter.region_id) {
        params['regionId'] = this.filter.region_id;
      }
      if (this.filter.agent_id) {
        params['agentId'] = this.filter.agent_id;
      }
      if (this.filter.dealer_id) {
        params['dealer_id'] = this.filter.dealer_id;
      }
      if (this.payload.dealer_id) {
        params['dealerId'] = this.payload.dealer_id;
      }
      if (this.filter.status) {
        params['status'] = this.filter.status;
      }
      if (this.filter.regione_text) {
        params['regione_text'] = this.filter.regione_text;
      }
      if (this.filter.provincia_text) {
        params['provincia_text'] = this.filter.provincia_text;
      }
      if (this.filter.citta_text) {
        params['citta_text'] = this.filter.citta_text;
      }

      this.shopAffiliationService.postMassiveAssigned(params).subscribe(
        (response) => {
          this.shopAffiliationService.successAssignedAlert('Assegnato correttamente');
          this.dismissModal();

        },
        (err) => {
          this.shopAffiliationService.errorAssignedAlert(err.message);
          this.dismissModal();
        },
      );
    } else {
      const payload = {
        dealer_id: this.filter.dealer_id,
        id: this.selectedItems.map(item => item.id),
      };

      this.shopAffiliationService.postShopAffiliationListDetail(payload).subscribe(
        (response) => {
          this.shopAffiliationService.successAssignedAlert('Assegnato correttamente');
          this.dismissModal();

        },
        (err) => {
          this.shopAffiliationService.errorAssignedAlert(err.message);
          this.dismissModal();
        },
      );
    }
    for (const key in this.filter) {
      if (key !== 'list_id') {
        this.filter[key] = null;
      }
    }
  }

  saveDealer() {
    const payload = {
      dealer_id: this.payload.dealer_id,
      id: this.payloadDealer.id,
    };

    this.shopAffiliationService.postAddDealer(payload).subscribe(
      (response) => {
        this.shopAffiliationService.successAssignedAlert('Associato correttamente');
        this.dismissModal();

      },
      (err) => {
        this.shopAffiliationService.errorAssignedAlert(err.message);
        this.dismissModal();
      },
    );
  }

  saveVisit() {
    const payload = {
      id: this.payloadVisit.id,
      date: this.programmaVisitaForm.get('date').value,
    };
    this.shopAffiliationService.postScheduleVisit(payload).subscribe(
      (response) => {
        this.shopAffiliationService.successVisitAlert('programmata correttamente');
        this.dismissModal();

      },
      (err) => {
        this.shopAffiliationService.errorVisitAlert(err.message);
        this.dismissModal();
      },
    );
  }

  saveFeedback() {
    if (this.agentFeedBackForm.valid) {
      const itemId = this.payloadFeedBack;
      const body = this.agentFeedBackForm.value;

      this.shopAffiliationService.putAgentFeedback(itemId, body).subscribe(
        (response) => {

          this.shopAffiliationService.successFeedbackAlert('salvato correttamente');
          this.dismissModal();
          this.payloadFeedBack = null;
          this.loadListDetail(this.listId);
        },
        (err) => {
          this.shopAffiliationService.errorFeedbackAlert(err.message);
          this.dismissModal();
          this.payloadFeedBack = null;
          this.agentFeedBackForm = null;
          this.loadListDetail(this.listId);
        },
      );
    }
  }

  exportList(listId: any) {
    const params = {
      'per-page': 0,
      listId: this.listId,
    };

    if (this.filter.city_id) {
      params['cityId'] = this.filter.city_id;
    }
    if (this.filter.rag_sociale) {
      params['rag_sociale'] = this.filter.rag_sociale;
    }
    if (this.filter.province_id) {
      params['provinceId'] = this.filter.province_id;
    }
    if (this.filter.region_id) {
      params['regionId'] = this.filter.region_id;
    }
    if (this.filter.agent_id) {
      params['agentId'] = this.filter.agent_id;
    }
    if (this.filter.dealer_id) {
      params['dealerId'] = this.filter.dealer_id;
    }
    if (this.filter.status) {
      params['status'] = this.filter.status;
    }

    this.shopAffiliationService.getShopAffiliationListDetail(params).subscribe((data) => {
      let rows = data.data;

      let output = rows.map((row) => {
        return {
          id: row.id,
          agent_id: row.agent_id,
          agent_name: row.agent_name,
          'Company': row.rag_sociale,
          nome: row.nome,
          cognome: row.cognome,
          piva: row.piva,
          cod_fisc: row.cod_fisc,
          'email': row.email,
          telephone: row.telephone,
          address: row.address,
          city_name: row.city_name,
          province_name: row.province_name,
          region_name: row.region_name,
          cap: row.cap,
          'Data Visita': row.visit_data !== null ? row.visit_data.datetime : '',
          'Stato': row.status,
          visit_id: row.visit_id,
          'Affiliato': row.dealer_id === null ? 'no' : 'si',
          'Questionario': row.visit_data !== null ? row.visit_data.json_data : '',
        };
      });

      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(output);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'export-shop-list-detail.xlsx');
    });
  }

  protected readonly Object = Object;

  objectKeys(jsonQuestions: any) {
    return Object.keys(jsonQuestions);
  }

  getVisitStatusLabel(statusValue: string) {
    return ShopAffiliationListDetail.statusType[statusValue];
  }

  goBack() {
    this._location.back();
  }

  toggleEdit(item: any, field: string) {
    if (this.editingField && this.editingField.id === item.id && this.editingField.field === field) {
      this.editingField = null;
    } else {
      this.editingField = { id: item.id, field: field };
    }
  }

  confirmEdit(item: any, field: string) {
    this.editingField = null;

    const orderId = item.id;
    const body: any = {};

    if (field === 'visit_id') {
      body.visit_id = item.visit_id;
    } else if (field === 'visit_id_schedule') {
      body.visit_id_schedule = item.visit_id_schedule;
    }

    this.changeOperatorStatus(orderId, body);
  }

}
