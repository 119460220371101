import { BaseModel } from '@models/base-model';
import { MetaPagination } from '@models/meta-pagination';

export class StoreCategory extends BaseModel {
  id: number;
  id_padre: number;
  nome: string;
  immagine_path: string;
  descrizione: string;
  featured?: any;
  parent_category: string;
  operator: string;
  id_gestore?: number;
  id_brand_telefonia: number;
  brand: string;
  file: File;
  order: number;

  fromArray(result: []) {
    const array: StoreCategory[] = [];
    for (const item of result) {
      array.push(new StoreCategory(item));
    }
    return array;
  }

  get postPayloadCategory() {
    const payload = new FormData();
    this.addToPayload(payload, this.nome, 'nome');
    this.addToPayload(payload, this.id_padre, 'id_padre');
    this.addToPayload(payload, this.id_gestore, 'id_gestore');
    this.addToPayload(payload, this.immagine_path, 'immagine_path');
    this.addToPayload(payload, this.id_brand_telefonia, 'id_brand_telefonia');
    this.addToPayload(payload, this.descrizione, 'descrizione');
    this.addToPayload(payload, this.featured, 'featured');
    this.addToPayload(payload, this.file, 'file');
    return payload;
  }
}

export class StoreCategoryPagination extends BaseModel {
  data: StoreCategory[];
  _meta: MetaPagination;
  _links: Array<any>;
}
