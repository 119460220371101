<!-- HEADER -->
<app-master-header
  cardClass="bg-gradient-info"
  image="assets/img/flats/destination.png"
  title="Dettaglio Lista"
>
  <div class="row align-items-end">
    <div class="col-lg-12 d-md-inline-flex">

      <div class="col-12 col-lg-4 col-md-4">
        <label>Ragione Sociale</label>
        <input
          type="text"
          class="form-control"
          [(ngModel)]="filter.rag_sociale"
          (keyup.enter)="loadListDetail(this.listId)"
          id="rag_sociale"
        >
      </div>
      <div class=" col-12 col-lg-3 col-md-3" *show="['operator', 'master']">
        <label>Key Account</label>
        <app-agent-select
          (onChange)="onAgentChange($event)"
          id="agent"
        ></app-agent-select>
      </div>
      <div class="col-12 col-lg-2 col-md-2">
        <div class="form-group">
          <label translate>Status</label>
          <ng-select
            [clearable]="true"
            class="disableClear"
            [items]="statuses"
            bindLabel="label"
            name="subscribed"
            id="subscribed"
            bindValue="key"
            [searchable]="true"
            (change)="onStatusChange($event)"
          ></ng-select>
        </div>
      </div>
      <div class=" col-12 col-lg-3 col-md-3">
        <label>Dealer ID</label>
        <app-retailer-typeahead
          (onChange)="onDealerChange($event)"
          id="companyName"
        ></app-retailer-typeahead>
      </div>
    </div>

    <div class="col-lg-12 d-md-inline-flex col-12">
      <div class="col-12 col-lg-2 col-md-2">
        <label>Cerca in Regione</label>
        <input
          type="text"
          class="form-control"
          [(ngModel)]="filter.regione_text"
          (keyup.enter)="loadListDetail(this.listId)"
          id="regione_text"
        >
      </div>
      <div class="col-12 col-lg-2 col-md-2">
        <label>Cerca in Provincia</label>
        <input
          type="text"
          class="form-control"
          [(ngModel)]="filter.provincia_text"
          (keyup.enter)="loadListDetail(this.listId)"
          id="provincia_text"
        >
      </div>
      <div class="col-12 col-lg-2 col-md-2">
        <label>Cerca in Citta</label>
        <input
          type="text"
          class="form-control"
          [(ngModel)]="filter.citta_text"
          (keyup.enter)="loadListDetail(this.listId)"
          id="citta_text"
        >
      </div>
      <div class="col-12 col-lg-2 col-md-2">
        <label>Regione</label>
        <app-state-select
          (onChange)="onRegionChange($event)"
          id="region"
        ></app-state-select>
      </div>
      <div class="col-12 col-lg-2 col-md-2">
        <label>Provincia</label>
        <app-province-select
          (onChange)="onProvinceChange($event)"
          [onDemand]="true"
          [state]="this.filter.region_id"
          id="province"
        ></app-province-select>
      </div>
      <div class="col-12 col-lg-2 col-md-2">
        <label>Città</label>
        <app-city-select
          (onChange)="onCityChange($event)"
          [onDemand]="true"
          [province]="this.filter.province_id"
          id="city"
        ></app-city-select>
      </div>
    </div>
  </div>
</app-master-header>

<!-- PULSANTE INDIETRO -->
<div class="container-fluid mb-3">
  <div class="row">
    <div class="col-12">
      <button
        class="btn btn-success"
        (click)="goBack()"
      >
        <i class="fa fa-arrow-left"></i> Torna indietro
      </button>
    </div>
  </div>
</div>

<div class="card">
  <div class="card-header">
    <div class="d-flex justify-content-between align-items-center">
      <h4 class="mb-0">{{ nameList }}</h4>
      <button *show="['operator', 'master']"
              (click)="showModal()"
              [disabled]="selectedItems.length === 0 && allChecked === false"
              class="btn btn-dark ml-auto">
        <i class="fa fa-download"></i> Assegna lista
      </button>
    </div>
  </div>
  <div class="card-body p-0 rounded-bottom table-responsive">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="square-jelly-box"
                 name="table-orders" [fullScreen]="false">
      <p style="color: white">Caricamento...</p>
    </ngx-spinner>
    <table class="table table-sm table-striped table-bordered table-hover">
      <thead>
      <tr>
        <th class="text-center p-2" *show="['operator', 'master']">
          <input type="checkbox" [(ngModel)]="allChecked" (click)="selectAll($event)">
        </th>
        <th class="p-2" scope="col" translate>Ragione Sociale</th>
        <th class="p-2" scope="col" *show="['operator', 'agent', 'master']">Associa</th>
        <th class="p-2" scope="col" translate>P.Iva</th>
        <th class="p-2" scope="col" translate>Regione</th>
        <th class="p-2" scope="col" translate>Provincia</th>
        <th class="p-2" scope="col" translate>Città</th>
        <th class="p-2" scope="col" translate>Indirizzo</th>
        <th scope="col" class="text-center p-2">Assegnato a</th>
        <th scope="col" class="text-center p-2" *show="['master', 'operator', 'agent']">Programma Visita</th>
        <th scope="col" class="text-center p-2"
            *show="['operator','masteragent', 'master', 'submaster', 'agent']">
          Programmata il
        </th>
        <th scope="col" class="text-center p-2">Stato</th>
        <th scope="col" class="text-center p-2">Feedback</th>
      </tr>
      </thead>
      <tbody>
      <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="square-jelly-box" name="orders"
                   [fullScreen]="false">
        <p style="color: white">Caricamento...</p>
      </ngx-spinner>
      <tr *ngIf="shopListDetail.length == 0">
        <td translate colspan="14" class="text-center">List is empty</td>
      </tr>
      <tr *ngFor="let item of shopListDetail; let i = index">
        <!-- CHECKBOX -->
        <td class="text-center p-2" *show="['operator', 'master']">
          <input type="checkbox" [(ngModel)]="item.selected" (change)="toggleSelection(item.id)">
        </td>
        <!-- RAGIONE SOCIALE -->
        <td>
          <div class="">
            <p class="my-auto">{{ item?.rag_sociale }}</p>
          </div>
        </td>
        <!-- ASSOCIA -->
        <td class="text-center" *show="['operator', 'master', 'agent']">
          <button class="btn btn-link" (click)="addDealerLink(item)" [disabled]="item.status !== 'visitato'">
            <i *ngIf="item?.dealer_id" class="fa fa-eye text-info" aria-hidden="true"></i>
            <i *ngIf="!item?.dealer_id" class="fa fa-link text-secondary" aria-hidden="true"></i>
          </button>
        </td>
        <!-- P.IVA -->
        <td>
          {{ item?.piva === null ? '-' : item?.piva }}
        </td>
        <!-- REGIONE -->
        <td>
          {{ item?.region_name === null ? '-' : item?.region_name }}
        </td>
        <!-- PROVINCIA -->
        <td>
          {{ item?.province_name === null ? '-' : item?.province_name }}
        </td>
        <!-- CITTA -->
        <td>
          {{ item?.city_name === null ? '-' : item?.city_name }}
        </td>
        <!-- INDIRIZZO -->
        <td>
          {{ item?.address === null ? '-' : item?.address }}
        </td>
        <!-- ASSEGNATO A -->
        <td class="text-center">
          {{ item?.agent_name === null ? '-' : item?.agent_name }}
        </td>


        <!-- Campo visit_id_schedule -->
        <td class="text-center" *show="['operator', 'master', 'agent']">
          <ng-container *ngIf="!editingField?.id || (editingField?.id !== item.id || editingField?.field !== 'visit_id_schedule'); else editModeVisitIdSchedule">
            <button
              (click)="showModalAgent(item)"
              class="btn btn-link"
              [disabled]="item.note !== null || item.status !== 'da-programmare'"
            >
              <i class="fa fa-calendar text-info" [ngClass]="{'text-danger': item.expired == true}"></i>
            </button>
            <span *ngIf="item.scheduled !== null && item.expired == true" class="text-danger">Scaduto</span>

            <ng-container *show="['master']">
              <button class="btn btn-sm btn-link p-0" (click)="toggleEdit(item, 'visit_id_schedule')">
                <i class="fas fa-pencil-alt ml-1 text-info"></i>
              </button>
            </ng-container>
          </ng-container>

          <ng-template #editModeVisitIdSchedule>
            <div class="d-flex">
              <div class="form-group-sm">
                <input type="text" [(ngModel)]="item.visit_id_schedule" />
              </div>
              <button class="btn btn-sm btn-link p-0" (click)="confirmEdit(item, 'visit_id_schedule')">
                <i class="fas fa-check ml-1 text-info"></i>
              </button>
            </div>
          </ng-template>
        </td>

        <!-- Campo visit_id -->
        <td class="text-center" *show="['operator', 'master', 'submaster', 'masteragent', 'agent']">
          <ng-container *ngIf="!editingField?.id || (editingField?.id !== item.id || editingField?.field !== 'visit_id'); else editModeVisitId">
            {{ item?.scheduled === null ? '-' : item?.scheduled | date:'dd-MM-yyyy' }}

            <ng-container *show="['master']">
              <button class="btn btn-sm btn-link p-0" (click)="toggleEdit(item, 'visit_id')">
                <i class="fas fa-pencil-alt ml-1 text-info"></i>
              </button>
            </ng-container>
          </ng-container>

          <ng-template #editModeVisitId>
            <div class="d-flex">
              <div class="form-group-sm">
                <input type="text" [(ngModel)]="item.visit_id" />
              </div>
              <button class="btn btn-sm btn-link p-0" (click)="confirmEdit(item, 'visit_id')">
                <i class="fas fa-check ml-1 text-info"></i>
              </button>
            </div>
          </ng-template>
        </td>




        <!-- STATUS -->
        <ng-container *show="['master', 'operator']">
          <td class="text-center">
            <button
              href="javascript:void(0)"
              class="btn btn-sm text-uppercase btn-block"
              (click)="openPopover(statusPopover, item?.id, item?.status, 'status', statuses)"
              #statusPopover="ngbPopover"
              autoClose="outside"
              triggers="manual"
              [ngbPopover]="popContent"
              popoverTitle="Cambia valore"
              [ngClass]="{
                  'btn-warning': item.status === 'da-programmare',
                  'btn-info': item.status === 'programmata' || item.status === 'assegnato',
                  'btn-primary': item.status === 'visitato',
                  'btn-success': item.status === 'affiliato',
                  'btn-secondary': item.status === 'gia-nostro-affiliato' || item.status === 'altro' ,
                  'btn-danger': item.status === 'chiuso',
                  'btn-dark': item.status === 'non-interessato'
                }"
            >
              {{ item?.visitStatus }}
            </button>
          </td>
        </ng-container>

        <ng-container *show="['agent']">
          <td class="text-center">
            <ng-container *ngIf="item.expired == false">
              <p class="badge"
                 [ngClass]="{
                  'badge-warning': item.status === 'da-programmare',
                  'badge-info': item.status === 'programmata' || item.status === 'assegnato',
                  'badge-primary': item.status === 'visitato',
                  'badge-success': item.status === 'affiliato' || item.status === 'gia-nostro-affiliato' || item.status === 'altro',
                  'badge-danger': item.status === 'chiuso',
                  'badge-dark': item.status === 'non-interessato'
                }"
              >{{ item?.status === null ? '-' : item?.visitStatus }}</p>
            </ng-container>
            <ng-container *ngIf="item.expired == true">
              <p class="badge badge-secondary">Da Riprogrammare</p>
            </ng-container>
          </td>
        </ng-container>
        <!-- FEEDBACK -->
        <ng-container *show="['operator','master', 'masteragent', 'submaster']">
          <td class="text-center" *ngIf="item.note == null">
            <p class="text-center"> - </p>
          </td>
          <td class="text-center" *ngIf="item.note != null">
            <button
              (click)="showOperatorFeedback(item)"
              class="btn bg-transparent text-secondary btn-block">
              <i class=" fa fa-eye text-info"></i>
            </button>
          </td>
        </ng-container>
        <ng-container *show="['agent']">
          <td class="text-center" *ngIf="item.note == null">
            <button
              (click)="showAgentFeedback(item)"
              class="btn bg-transparent text-info btn-block">
              <i class="fas fa-pencil-alt"></i>
            </button>
          </td>
          <td class="text-center" *ngIf="item.note != null">
            <button
              (click)="showAgentFeedback(item)"
              class="btn bg-transparent text-secondary btn-block">
              <i class="fas fa-eye"></i>
            </button>
          </td>
        </ng-container>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="card-footer" *ngIf="shopListDetail?.length > 0">
    <span
    ><span
      translate>There are </span><b>{{ this.ordersPagination.totalCount }}</b> {{ this.ordersPagination.totalCount <= 1 ? 'Risultato' : ('Risultati' | translate) }}
      . <a class="link" (click)="exportList(this.listId)"> Esporta la lista completa</a>
    </span>
    <ngb-pagination
      *ngIf="ordersPagination.pageCount > 1"
      size="sm"
      [pageSize]="ordersPagination.perPage"
      [maxSize]="10"
      [(page)]="ordersPagination.currentPage"
      [collectionSize]="ordersPagination.totalCount"
      [boundaryLinks]="true"
      (pageChange)="loadListDetail(this.listId)"
    >
    </ngb-pagination>
  </div>
</div>


<ng-template #assegnaListaTemplate let-modal>
  <div class="modal-header bg-gradient-dark">
    <h4 class="modal-title clamp-title" translate>{{ 'Assegna Lista' }}</h4>
    <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form (ngSubmit)="save()" [formGroup]="assegnaListaForm">
      <div class="mt-2 mb-0">
        <div class="form-group">
          <label class="lead mb-1">Seleziona un Key Account</label>
          <app-agent-select (onChange)="setScopeId($event)"></app-agent-select>

        </div>
        <button class="btn btn-secondary btn-block mb-2" type="submit">
          <i class="fa fa-check-circle mr-2"></i> <span translate>Assegna</span>
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #addDealerTemplate let-modal>
  <div class="modal-header bg-gradient-info">
    <h4 class="modal-title clamp-title"
        translate>{{ payloadDealer?.dealer_id ? 'Dealer Associato' : 'Associa Dealer' }}</h4>
    <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="!payloadDealer?.dealer_id">
      <form (ngSubmit)="saveDealer()" [formGroup]="addDealerForm">
        <div class="mt-2 mb-0">
          <div class="form-group">
            <label class="lead mb-1">Seleziona un Dealer</label>
            <app-retailer-typeahead (onChange)="setDealerScopeId($event)"></app-retailer-typeahead>
          </div>
          <button class="btn btn-info btn-block mb-2" type="submit">
            <i class="fa fa-check-circle mr-2"></i> <span translate>Associa</span>
          </button>
        </div>
      </form>
    </ng-container>
    <ng-container *ngIf="payloadDealer?.dealer_id">
      <p>{{ payloadDealer?.rag_sociale }}</p>
    </ng-container>
  </div>
</ng-template>

<ng-template #programmaVisitaTemplate let-modal>
  <div class="modal-header bg-gradient-dark">
    <h4 class="modal-title clamp-title" translate>{{ 'Programma Visita' }}</h4>
    <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form (ngSubmit)="saveVisit()" [formGroup]="programmaVisitaForm">
      <div class="mt-2 mb-0">
        <div class="form-group">
          <label>Seleziona la data</label>
          <input
            class="form-control"
            placeholder="gg-mm-aaaa"
            ngbDatepicker
            #data="ngbDatepicker"
            formControlName="date"
            (click)="data.toggle()"
          />
        </div>
        <button class="btn btn-secondary btn-block mb-2" type="submit">
          <i class="fa fa-check-circle mr-2"></i> <span translate>Assegna</span>
        </button>
      </div>
    </form>
  </div>
</ng-template>


<ng-template #agentFeedBackTemplate let-modal>
  <div class="modal-header bg-gradient-dark">
    <h4 class="modal-title clamp-title" translate>Inserisci un Feedback</h4>
    <button type="button" class="close text-white" aria-label="Close" (click)="dismissModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form (ngSubmit)="saveFeedback()" [formGroup]="agentFeedBackForm">
      <div class="mt-2 mb-0">
        <div class="form-group">
          <label class="lead mb-1">Seleziona uno stato</label>
          <ng-select formControlName="status" id="nameList" [(ngModel)]="visitStatus">
            <ng-option value="gia-nostro-affiliato">{{ getVisitStatusLabel('gia-nostro-affiliato') }}</ng-option>
            <ng-option value="non-interessato">{{ getVisitStatusLabel('non-interessato') }}</ng-option>
            <ng-option value="chiuso">{{ getVisitStatusLabel('chiuso') }}</ng-option>
            <ng-option value="altro">{{ getVisitStatusLabel('altro') }}</ng-option>
          </ng-select>
          <val-errors controlName="status">
            <ng-template valError="required">
              Lo stato del negozio è obbligatorio
            </ng-template>
          </val-errors>
        </div>

        <div class="form-group">
          <label class="lead mb-1">Feedback</label>
          <textarea
            formControlName="note"
            [(ngModel)]="noteAgent"
            id="description"
            class="form-control"
          ></textarea>
          <val-errors controlName="note">
            <ng-template valError="required">
              Il campo Note è obbligatorio
            </ng-template>
          </val-errors>
        </div>
        <button class="btn btn-secondary btn-block mb-2" type="submit"
                [disabled]="!agentFeedBackForm.valid">
          <i class="fa fa-check-circle mr-2"></i> <span translate>Salva</span>
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #operatorFeedBackTemplate let-modal>
  <div class="modal-header bg-gradient-dark">
    <h4 class="modal-title clamp-title" translate>{{ 'Dettagli Visita' }}</h4>
    <button type="button" class="close text-white" aria-label="Close" (click)="dismissModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <ng-container *ngIf="modalVisitData.visit_data !== null">
    <div class="modal-body">
      <div class="card">
        <div class="card-header bg-light">
          <div class="row">
            <div class="col-4">
              <h2 class="text-uppercase font-weight-bold mb-0 text-lg">Dati Negozio</h2>
            </div>
            <div class="col-8">
              <div class="d-flex justify-content-end text-uppercase">
                <p class="font-weight-bold">Stato:</p>
                <p class="ml-2">{{ modalVisitData.status }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row p-0 mb-3">
            <div class="col-4">
              <p class="lead font-weight-bold mb-1">Ragione Sociale:</p>
              <p class="">{{ modalVisitData.rag_sociale }}</p>
            </div>
            <div class="col-4">
              <p class="lead font-weight-bold mb-1">Nome:</p>
              <p class="">{{ modalVisitData?.nome === null ? '-' : modalVisitData?.nome }}</p>
            </div>
            <div class="col-4">
              <p class="lead font-weight-bold mb-1">Cognome:</p>
              <p class="">{{ modalVisitData?.cognome === null ? '-' : modalVisitData?.cognome }}</p>
            </div>
          </div>
          <div class="row p-0 mb-3">
            <div class="col-4">
              <p class="lead font-weight-bold mb-1">P. Iva:</p>
              <p class="">{{ modalVisitData?.piva === null ? '-' : modalVisitData?.piva }}</p>
            </div>
            <div class="col-4">
              <p class="lead font-weight-bold mb-1">Cod. Fiscale:</p>
              <p class="">{{ modalVisitData?.cod_fisc === null ? '-' : modalVisitData?.cod_fisc }}</p>
            </div>
            <div class="col-4">
              <p class="lead font-weight-bold mb-1">Email:</p>
              <p class="">{{ modalVisitData?.email === null ? '-' : modalVisitData?.email }}</p>
            </div>
          </div>
          <div class="row p-0 mb-3">
            <div class="col-4">
              <p class="lead font-weight-bold mb-1">Telefono:</p>
              <p class="">{{ modalVisitData?.telephone === null ? '-' : modalVisitData?.telephone }}</p>
            </div>
            <div class="col-4">
              <p class="lead font-weight-bold mb-1">Indirizzo:</p>
              <p class="">{{ modalVisitData?.address === null ? '-' : modalVisitData?.address }}</p>
            </div>
            <div class="col-4">
              <p class="lead font-weight-bold mb-1">Città</p>
              <p class="">{{ modalVisitData?.city_name === null ? '-' : modalVisitData?.city_name }}</p>
            </div>
          </div>
          <div class="row p-0 mb-3">
            <div class="col-4">
              <p class="lead font-weight-bold mb-1">Provincia:</p>
              <p class="">{{ modalVisitData?.province_name === null ? '-' : modalVisitData?.province_name }}</p>
            </div>
            <div class="col-4">
              <p class="lead font-weight-bold mb-1">Regione:</p>
              <p class="">{{ modalVisitData?.region_name === null ? '-' : modalVisitData?.region_name }}</p>
            </div>
            <div class="col-4">
              <p class="lead font-weight-bold mb-1">CAP</p>
              <p class="">{{ modalVisitData?.cap === null ? '-' : modalVisitData?.cap }}</p>
            </div>
          </div>
          <div class="row p-0 mb-3">
            <div class="col-12">
              <p class="lead font-weight-bold mb-1">Note:</p>
              <p class="">{{ modalVisitData?.note === null ? 'Note non disponibili' : modalVisitData?.note }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header bg-light">
          <div class="row">
            <div class="col-4">
              <h2 class="text-uppercase font-weight-bold mb-0 text-lg">Dati Visita</h2>
            </div>
            <div class="col-8">
              <div class="d-flex justify-content-end text-uppercase">
                <p class="font-weight-bold">Affiliato:</p>
                <p class="ml-2">{{ affiliationText }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row p-0 mb-3">
            <div class="col-4">
              <p class="lead font-weight-bold mb-1">Negozio:</p>
              <p class="">{{ modalVisitData?.visit_data.shop === null ? '-' : modalVisitData?.visit_data.shop }}</p>
            </div>
            <div class="col-4">
              <p class="lead font-weight-bold mb-1">Agente:</p>
              <p
                class="">{{ modalVisitData?.visit_data.agent_name === null ? '-' : modalVisitData?.visit_data.agent_name }}</p>
            </div>
            <div class="col-4">
              <p class="lead font-weight-bold mb-1">Visitato il:</p>
              <p class="">{{ modalVisitData?.visit_data.datetime |  date:'dd/MM/yyyy -  HH:MM:ss' }}</p>
            </div>
          </div>
          <h2 class="text-uppercase font-weight-bold my-5 text-center text-md">Questionario</h2>
          <div class="row p-0">
            <div class="col-12">
              <ng-container *ngIf="jsonQuestions && jsonQuestions.length > 0">
                <div *ngFor="let question of jsonQuestions">
                  <p class="lead font-weight-bold pl-0 mb-1">{{ question.key }}:</p>
                  <p>
                    {{ question.value }}
                  </p>
                </div>
              </ng-container>
              <p *ngIf="!jsonQuestions || objectKeys(jsonQuestions).length === 0">Nessuna Questionario
                disponibile.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="modalVisitData.visit_data == null">
    <div class="modal-body">
      <label class="lead mb-1">Feedback</label>
      <textarea
        [value]="modalVisitData.note"
        [disabled]="true"
        class="form-control"
      ></textarea>
    </div>
  </ng-container>
</ng-template>

<ng-template #popContent>
  <app-edit-popup [config]="popupConfig" (onSave)="updateStatus($event)"></app-edit-popup>
</ng-template>