import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';
import { AlertService } from '@services/alert.service';

@Injectable({
    providedIn: 'root',
})
export class PartnershipService {
    constructor(private request: RequestService, public alertService: AlertService) {}

    // public satispayUploadFile(formData) {
    //   return this.request.post('v3/partnership/import-satispay-file', formData);
    // }

    // public iPostUploadFile(formData) {
    //   return this.request.post('v3/partnership/import-in-post-file', formData);
    // }

    public uploadFile(formData: FormData, mode: 'satispay' | 'satispay-analisi' | 'inpost' | 'indabox' | 'ups') {
        let endpoint: string;

        switch (mode) {
            case 'satispay':
                endpoint = 'v3/partnership/import-satispay-file';
                break;
            case 'satispay-analisi':
                endpoint = 'v3/partnership/import-satispay-analisi';
                break;
            case 'inpost':
                endpoint = 'v3/partnership/import-in-post-file';
                break;
            case 'indabox':
                endpoint = 'v3/partnership/import-indabox-file';
                break;
            case 'ups':
                endpoint = 'v3/partnership/import-ups-file';
                break;
            default:
                throw new Error('Modalità non supportata');
        }
        return this.request.post(endpoint, formData);
    }

    public successAlert(text) {
        this.alertService.show('', 'File ' + text, { classname: 'bg-success text-light', delay: 2000 });
    }

    public errorAlert(message?) {
        this.alertService.show('Errore nel caricamento del file ', message ? message : 'Riprova di nuovo!', {
            classname: 'bg-danger text-light',
            delay: 2000,
        });
    }
}