import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Partnership } from '@app/models/partnership';
import { PartnershipService } from '@app/services/partnership.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-partnership-modal',
    templateUrl: './partnership-modal.component.html',
    styleUrls: ['./partnership-modal.component.css'],
})
export class PartnershipModalComponent implements OnInit {
    @Input() mode: 'satispay' | 'satispay-analisi' | 'inpost' | 'indabox' | 'ups';
    @Output() refresh = new EventEmitter<boolean>();

    partnershipForm: FormGroup;
    file: File | null = null;

    loadingExport = false;

    @ViewChild('documentFormTemplate') private documentFormTemplate;

    constructor(public modalService: NgbModal, public formBuilder: FormBuilder, private service: PartnershipService) {}

    ngOnInit(): void {
        this.partnershipForm = this.formBuilder.group({
            fileInput: [null, [Validators.required]],
        });
    }

    onFileSelected(event: any) {
        this.file = event.target.files[0];
    }

    save() {
        if (this.file) {
            const formData = new FormData();
            formData.append('file', this.file);

            this.loadingExport = true;
            this.service.uploadFile(formData, this.mode).subscribe(
                (response: Partnership) => {
                    this.service.successAlert('importato correttamente');
                    this.refresh.emit(true);
                    this.dismissModal();
                    this.loadingExport = null;
                },
                (err) => {
                    this.service.errorAlert(err.message);
                    this.refresh.emit(true);
                    this.dismissModal();
                    this.loadingExport = null;
                },
            );
        }
    }

    dismissModal() {
        this.modalService.dismissAll();
    }
}