<!--<app-master-header-->
<!--  cardClass="bg-gradient-1"-->
<!--  title="Gestione Pagamenti"-->
<!--  [image]="'flats/payment.png' | assets"-->
<!--  [ngBusy]="busy"-->
<!--&gt;-->
<!--  <p class="lead">-->
<!--    Da qui puoi gestire i pagamenti automatici selezionandoli e generando l'xml-->
<!--    per il CBI.-->
<!--  </p>-->
<!--</app-master-header>-->


<div class="row" [ngBusy]="busy">
  <div class="col-12">
    <div class="form-group">
      <input
        type="text"
        class="form-control"
        placeholder="Filtra i dealer"
        [(ngModel)]="filter"
      />
    </div>
  </div>
  <div class="col" style="height: 700px; overflow-y: scroll">
    <div class="card bg-gradient-light" *ngFor="let item of filteredDealers">
      <div class="card-header">
        <div class="user-block">
          <img class="img-circle" src="../../../assets/img/user2-160x160.jpg" />
          <a class="username" [routerLink]="['/gestione-crediti/gestione-credito', item.id]" target="_blank"
            ><b>{{ item.company_name }}</b></a
          >
          <span class="description">
            IBAN: <b [ngClass]="{'text-danger': !item.ibanIsValid}">{{ item.iban }}</b>
            <span *ngIf="item.blacklist === 1" class="text-danger ml-2"
              ><i class="fas fa-exclamation-circle"></i> Blacklist</span
            >
              <span *ngIf="item.deleted === 1" class="text-danger ml-2"
              ><i class="fas fa-exclamation-circle"></i> Cancellato</span
            >
          </span>
        </div>
        <div class="card-tools">
          <span class="text-muted">Bilancio: </span>
          <span class="text-lg">{{
            additionalData[item.id].balance | currency: "€"
          }}</span>
        </div>
      </div>
      <div class="card-body p-0">
        <table class="table table-sm table-striped">
          <thead>
            <tr>
              <th class="text-center" width="5%">
                <input
                  type="checkbox"
                  [disabled]="this.shopSetting.setting_value === 'yes'"
                  (change)="toggleAllTransactions($event, item)"
                />
              </th>
              <th class="text-center" width="10%">Scadenza</th>
              <th class="text-left">Descrizione</th>
                <th class="text-left" width="10%">Operatori</th>
              <th class="text-right" width="10%">Entrate</th>
              <th class="text-right" width="10%">Uscite</th>
              <th class="text-center" width="5%">Saldato</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let tr of item.unweldedTransactions">
              <td class="text-center">
                <input
                  type="checkbox"
                  [disabled]="this.shopSetting.setting_value === 'yes'"
                  [(ngModel)]="tr.checked"
                  (ngModelChange)="updateDealerBalance(item)"
                />
              </td>
              <td class="text-center">
                {{ tr.scadenza | amDateFormat: "DD/MM/YYYY" }}
              </td>
              <td class="text-left">
                {{ tr.descrizione }}
              </td>
                <td class="text-left">
                    {{ tr.invoice_types }}
                </td>
              <td class="text-right">
                <span *ngIf="tr.segno === 1">{{
                  tr.valore | currency: "€"
                }}</span>
              </td>
              <td class="text-right">
                <span *ngIf="tr.segno === 0">{{
                  tr.valore | currency: "€"
                }}</span>
              </td>
              <td class="text-center">
                <i
                  class="fas"
                  [ngClass]="{
                    'fa-times-circle text-danger': tr.saldato === 0,
                    'fa-check-circle text-success': tr.saldato === 1
                  }"
                ></i>
              </td>
            </tr>
            <tr class="border-top">
              <th class="text-center"></th>
              <th class="text-center"></th>
              <th class="text-left ">Saldo totale</th>
                <th class="text-center"></th>
              <th class="text-right text-success">
                <span *ngIf="item.unweldedBalance < 0">{{
                  -item.unweldedBalance | currency: "€"
                }}</span>
              </th>
              <th class="text-right text-danger">
                <span *ngIf="item.unweldedBalance > 0">{{
                  item.unweldedBalance | currency: "€"
                }}</span>
              </th>
              <th class="text-center"></th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-3" *show="['master']">
    <div class="card bg-gradient-light">
      <div class="card-header">
        <p class="display-6 d-inline-block">Riepilogo</p>
        <div class="card-tools">
          <button
            *ngIf="this.shopSetting"
            (click)="toggleShopSetting()"
            class="btn btn-sm btn-danger"
            [ngClass]="{
              'btn-danger': this.shopSetting.setting_value === 'yes',
              'btn-success': this.shopSetting.setting_value === 'no'
            }"
          >
            <span *ngIf="this.shopSetting.setting_value === 'no'"
              >Sblocca
            </span>
            <span *ngIf="this.shopSetting.setting_value === 'yes'">Blocca </span>
            Negozio
          </button>
        </div>
      </div>
      <div class="card-body p-0">
        <table class="table">
          <tbody>
            <tr>
              <td>Voci selezionate</td>
              <th class="text-right">{{ this.totalVoices }}</th>
            </tr>
            <tr>
              <td>Totale a pagare</td>
              <th class="text-right">{{ this.balance | currency: "€" }}</th>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-footer">
        <button class="btn btn-info btn-block btn-sm" (click)="previewCbi()" [disabled]="totalVoices === 0">
          <i class="fas fa-eye"></i> Anteprima CBI
        </button>
        <button
          class="btn btn-success btn-block btn-sm"
          (click)="saveCbi()"
          [disabled]="cbiDisabled"
        >
          <i class="fas fa-file-invoice-dollar"></i> Salva CBI
        </button>
      </div>
    </div>
  </div>
</div>
