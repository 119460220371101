<!-- <div class="card bg-gradient-white elevation-1 category p-3 mb-4">
</div> -->
<h2 class="text-bold display-8 my-5 w-75 text-uppercase">Importa facilmente i file dei tuoi servizi</h2>

<!-- CARD -->

<div class="row">
    <div class="col-12 col-md-3">
        <div class="card">
            <div class="card-body">
                <h3 class="card-title mb-1 text-bold">SATISPAY</h3>
                <p class="card-text">
                    Puoi utilizzare questa sezione per caricare il file e aggiornare i dati del nostro partner
                    <strong>SATISPAY</strong>.
                </p>
                <div class="d-flex">
                    <button type="button" class="btn btn-info px-3 mt-3 mr-3" (click)="showModal('satispay')">
                        Importa satispay
                    </button>
                    <button type="button" class="btn btn-primary px-3 mt-3" (click)="showModal('satispay-analisi')">
                        Importa analisi
                    </button>
                </div>
            </div>
            <div class="card-footer">
                <p class="text-muted">Il file deve contenere le colonne in questo modo:</p>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-3">
        <div class="card">
            <div class="card-body">
                <h3 class="card-title mb-1 text-bold">INPOST</h3>
                <p class="card-text">
                    Puoi utilizzare questa sezione per caricare il file e aggiornare i dati del nostro partner
                    <strong>INPOST</strong>.
                </p>
                <button type="button" class="btn btn-info px-3 mt-3" (click)="showModal('inpost')">Importa file</button>
            </div>
            <div class="card-footer">
                <p class="text-muted">Il file deve contenere le colonne in questo modo:</p>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-3">
        <div class="card">
            <div class="card-body">
                <h3 class="card-title mb-1 text-bold">INDABOX</h3>
                <p class="card-text">
                    Puoi utilizzare questa sezione per caricare il file e aggiornare i dati del nostro partner
                    <strong>INDABOX</strong>.
                </p>
                <button type="button" class="btn btn-info px-3 mt-3" (click)="showModal('indabox')">
                    Importa file
                </button>
            </div>
            <div class="card-footer">
                <p class="text-muted">
                    Il file deve contenere le colonne in questo modo: <br />
                    <span class="badge badge-dark">FRAZIONARIO</span> -
                    <span class="badge badge-dark">DENOMINAZIONE</span> -
                    <span class="badge badge-dark">PIVA</span>
                </p>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-3">
        <div class="card">
            <div class="card-body">
                <h3 class="card-title mb-1 text-bold">UPS</h3>
                <p class="card-text">
                    Puoi utilizzare questa sezione per caricare il file e aggiornare i dati del nostro partner
                    <strong>UPS</strong>.
                </p>
                <button type="button" class="btn btn-info px-3 mt-3" (click)="showModal('ups')">Importa file</button>
            </div>
            <div class="card-footer">
                <p class="text-muted">Il file deve contenere le colonne in questo modo: <br /></p>
            </div>
        </div>
    </div>
</div>

<!-- FINE CARD -->

<!-- MODALE -->

<ng-template #partnershipFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>
            {{
                mode === 'satispay'
                    ? 'Importa file SATISPAY '
                    : mode === 'satispay-analisi'
                    ? 'Importa file Analisi SATISPAY'
                    : mode === 'inpost'
                    ? 'Importa file INPOST'
                    : mode === 'indabox'
                    ? 'Importa file INDABOX'
                    : 'Importa file UPS'
            }}
        </h4>
        <!--      <h4 class="modal-title clamp-title" translate>{{mode === 'satispay' ? 'Importa file SATISPAY ' : 'Importa file Analisi SATISPAY'}}</h4>-->
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <app-partnership-modal [mode]="mode"></app-partnership-modal>
</ng-template>

<!-- FINE MODALE -->
