import { Component, OnInit, ViewChild } from '@angular/core';
import { KpiDashboardData, VisiteDashboard } from '@app/models/kpi-dashboard-data';
import { DashboardService } from '@app/services/dashboard.service';
import { MonthRangePickerComponent } from '@app/widgets/month-range-picker/month-range-picker.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { DataBuilderModalService } from '@app/services/data-builder-modal.service';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-kpi-dashboard',
    templateUrl: './kpi-dashboard.component.html',
    styleUrls: ['./kpi-dashboard.component.scss'],
})
export class KpiDashboardComponent implements OnInit {
    @ViewChild(MonthRangePickerComponent) monthRangePicker: MonthRangePickerComponent;
    @ViewChild('visiteDetailModal') visiteDetailModal;
    @ViewChild('kdCubeExpiringModal') kdCubeExpiringModal;
    @ViewChild('kdCubeExpiredModal') kdCubeExpiredModal;
    @ViewChild('kdCubePromoModal') kdCubePromoModal;
    @ViewChild('kdCubeAffiliatiModal') kdCubeAffiliatiModal;

    private hasInitialized: boolean = false;
    private dateSubject = new BehaviorSubject<{
        full_month: string;
        month: string;
        year: string;
        prev_month: string;
        prev_year: string;
        from_date: string;
        to_date: string;
    }>(this.getFormattedDateRange(moment()));

    date$ = this.dateSubject.asObservable();

    dbmBollettiniUrl: string = '';
    defaultMonth: moment.Moment[];
    period: string;
    apiFormat = 'YYYY-MM-DD';
    isLoading: boolean = false;
    dashboardData: KpiDashboardData;
    pafToggle: boolean = false;
    sortRechargeColumn: string = '';
    sortRechargeDirection: string = 'asc';
    sortCompensationColumn: string = '';
    sortCompensationDirection: string = 'asc';
    selectedVisit: VisiteDashboard;
    visitDetailModalTitle: string = '';

    constructor(
        private service: DashboardService,
        private spinner: NgxSpinnerService,
        public modalService: NgbModal,
        public dbm: DataBuilderModalService,
        private router: Router,
    ) {
        this.date$.subscribe(() => {
            if (this.hasInitialized) {
                this.loadData();
            } else {
                this.hasInitialized = true;
            }
        });

        window.onscroll = function () {
            const scrollBtn = document.getElementById('scrollToTopBtn');

            if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
                scrollBtn.style.display = 'block';
            } else {
                scrollBtn.style.display = 'none';
            }
        };
    }

    ngOnInit() {}

    getFormattedDateRange(date: moment.Moment) {
        const startDate = moment(date).startOf('month');
        const endDate = moment(date).endOf('month');

        return {
            full_month: startDate.format('MMMM'),
            month: startDate.format('MM'),
            year: startDate.format('YYYY'),
            prev_month: moment(startDate).subtract(1, 'months').format('MM'),
            prev_year: moment(startDate).subtract(1, 'months').format('YYYY'),
            from_date: startDate.format(this.apiFormat),
            to_date: endDate.format(this.apiFormat),
        };
    }

    setSelectedDateRange(date: any) {
        const formattedDateRange = this.getFormattedDateRange(moment(date));
        this.dateSubject.next(formattedDateRange);
        this.period = formattedDateRange.year + '-' + formattedDateRange.month;
    }

    loadData() {
        this.spinner.show('dashboard');
        const params = {
            from_date: this.dateSubject.value.from_date,
            to_date: this.dateSubject.value.to_date,
            paf: this.pafToggle,
        };

        this.service.getDashboardData(params).subscribe(
            (res) => {
                this.dashboardData = res;
                this.spinner.hide('dashboard');
            },
            (err) => {
                console.log('Errore nel recuperare i dati: ', err);
                this.spinner.hide('dashboard');
            },
        );
    }

    togglePaf() {
        this.pafToggle = !this.pafToggle;
        this.loadData();
    }

    openListDetail(id: number) {
        this.router.navigate(['shop-list-detail', id]);
    }

    openDettagliModal(kpi: string) {
        switch (kpi) {
            case 'bollettini':
                const dbmBollettiniUrl = `shop-orders/kd-sales?vendor=Kena%20Mobile&date%5Bcurrent_month%5D=${this.dateSubject.value.from_date};${this.dateSubject.value.to_date}&subset%5Bcurrent_month%5D=current_month_bulletin&sort%5Bcurrent_month_bulletin%5D=3`;
                this.dbm.show(dbmBollettiniUrl, 'Dettaglio Bollettini');
                break;
            case 'package':
                const dbmPackageUrl = `shop-orders/kd-sales?vendor=Kena%20Mobile&sort%5Bcurrent_month_package%5D=3&date%5Bcurrent_month%5D=${this.dateSubject.value.from_date};${this.dateSubject.value.to_date}&subset%5Bcurrent_month%5D=current_month_package`;
                this.dbm.show(dbmPackageUrl, 'Dettaglio Pacchi');
                break;
            case 'ricariche':
                const dbmRicaricheUrl = `shop-orders/kd-sales?vendor=Kena%20Mobile&sort%5Bcurrent_month_recharges_total%5D=3&date%5Bcurrent_month%5D=${this.dateSubject.value.from_date};${this.dateSubject.value.to_date}&subset%5Bcurrent_month%5D=current_month_recharges_total`;
                this.dbm.show(dbmRicaricheUrl, 'Dettaglio Ricariche');
                break;
            case 'active_kdcube':
                const dbmActiveKdcubeUrl = `shop-orders/kd-sales?vendor=Kena%20Mobile&filter%5Bcurrent_month_abbonato%5D=1&date%5Bcurrent_month%5D=${this.dateSubject.value.from_date};${this.dateSubject.value.to_date}&subset%5Bcurrent_month%5D=current_month_abbonato`;
                this.dbm.show(dbmActiveKdcubeUrl, 'Dettaglio Abbonati KD Cube');
                break;
            case 'kena_affiliations':
                const dbmKenaAffiliationsUrl = `v2/openings/request-list?vendor=Kena%20Mobile&date[current_month]=${this.dateSubject.value.from_date};${this.dateSubject.value.to_date}&filter%5Bcurrent_month_stato%5D=Gestito`;
                this.dbm.show(dbmKenaAffiliationsUrl, 'Dettaglio Affiliazioni Kena');
                break;
            case 'tim_affiliations':
                const dbmTimAffiliationsUrl = `v2/openings/request-list?vendor=Tim&date[current_month]=${this.dateSubject.value.from_date};${this.dateSubject.value.to_date}&filter%5Bcurrent_month_stato%5D=Gestito`;
                this.dbm.show(dbmTimAffiliationsUrl, 'Dettaglio Affiliazioni Tim');
                break;
            case 'a2a_affiliations':
                const dbmA2AAffiliationsUrl = `v2/openings/request-list?vendor=A2A&date[current_month]=${this.dateSubject.value.from_date};${this.dateSubject.value.to_date}&filter%5Bcurrent_month_stato%5D=Gestito`;
                this.dbm.show(dbmA2AAffiliationsUrl, 'Dettaglio Affiliazioni A2A');
                break;
            case 'kena_attivazioni':
                const dbmKenaAttivazioniUrl = `sales/ranking-shops?vendor=Kena%20Mobile&sort%5Bcurrent_month_sales%5D=3&date%5Bcurrent_month%5D=${this.dateSubject.value.from_date};${this.dateSubject.value.to_date}`;
                this.dbm.show(dbmKenaAttivazioniUrl, 'Dettaglio Attivazioni Kena');
                break;
            case 'tim_mobile_attivazioni':
                const dbmTimAttivazioniMobileUrl = `sales/ranking-shops?vendor=Tim&sort%5Bcurrent_month_sales%5D=3&date%5Bcurrent_month%5D=${this.dateSubject.value.from_date};${this.dateSubject.value.to_date}`;
                this.dbm.show(dbmTimAttivazioniMobileUrl, 'Dettaglio Attivazioni Tim Mobile');
                break;
            case 'tim_fisso_attivazioni':
                const dbmTimFissoAttivazioniUrl = `sales/ranking-shops?vendor=Tim&sort%5Bcurrent_month_sales%5D=3&date%5Bcurrent_month%5D=${this.dateSubject.value.from_date};${this.dateSubject.value.to_date}`;
                this.dbm.show(dbmTimFissoAttivazioniUrl, 'Dettaglio Attivazioni Tim Fisso');
                break;
            case 'a2a_attivazioni':
                const dbmA2aAttivazioniUrl = `sales/ranking-shops?vendor=A2A&sort%5Bcurrent_month_sales%5D=3&date%5Bcurrent_month%5D=${this.dateSubject.value.from_date};${this.dateSubject.value.to_date}`;
                this.dbm.show(dbmA2aAttivazioniUrl, 'Dettaglio Attivazioni A2A');
                break;
            case 'ecommerce':
                const dbmEcommerceUrl = `shop-orders/sales?vendor=Kena%20Mobile&type=accessories&sort%5Bcurrent_month_order_total%5D=3&date%5Bcurrent_month%5D=${this.dateSubject.value.from_date};${this.dateSubject.value.to_date}`;
                this.dbm.show(dbmEcommerceUrl, 'Dettaglio Ecommerce');
                break;
            case 'tim_attivazioni_al':
                const dbmTimAttivazioniAlUrl = `sales/ranking-shops?vendor=Tim&date%5Bcurrent_month%5D=${this.dateSubject.value.from_date};${this.dateSubject.value.to_date}&subset%5Bcurrent_month%5D=current_month_al&sort%5Bcurrent_month_al%5D=3`;
                this.dbm.show(dbmTimAttivazioniAlUrl, 'Dettaglio Attivazioni Mobile Tim');
                break;
            case 'tim_attivazioni_mnp':
                const dbmTimAttivazioniMnpUrl = `sales/ranking-shops?vendor=Tim&date%5Bcurrent_month%5D=${this.dateSubject.value.from_date};${this.dateSubject.value.to_date}&subset%5Bcurrent_month%5D=current_month_mnp&sort%5Bcurrent_month_mnp%5D=3`;
                this.dbm.show(dbmTimAttivazioniMnpUrl, 'Dettaglio Attivazioni Mobile Tim');
                break;
            case 'tim_attivazioni_fisso':
                const dbmTimAttivazioniFissoUrl = `sales/ranking-shops?vendor=Tim%20Fwa&date%5Bcurrent_month%5D=${this.dateSubject.value.from_date};${this.dateSubject.value.to_date}&sort%5Bcurrent_month_sales%5D=3`;
                this.dbm.show(dbmTimAttivazioniFissoUrl, 'Dettaglio Attivazioni Fisso Tim');
                break;
            case 'a2a_attivazioni_luce':
                const dbmA2aAttivazioniLuceUrl = `sales/ranking-shops?vendor=A2A&date%5Bcurrent_month%5D=${this.dateSubject.value.from_date};${this.dateSubject.value.to_date}&subset%5Bcurrent_month%5D=current_month_luce&sort%5Bcurrent_month_luce%5D=3`;
                this.dbm.show(dbmA2aAttivazioniLuceUrl, 'Dettaglio Attivazioni Luce A2A');
                break;
            case 'a2a_attivazioni_gas':
                const dbmA2aAttivazioniGasUrl = `sales/ranking-shops?vendor=A2A&date%5Bcurrent_month%5D=${this.dateSubject.value.from_date};${this.dateSubject.value.to_date}&subset%5Bcurrent_month%5D=current_month_gas&sort%5Bcurrent_month_gas%5D=3`;
                this.dbm.show(dbmA2aAttivazioniGasUrl, 'Dettaglio Attivazioni Gas A2A');
                break;
        }
    }

    sortRechargeData(column: string) {
        if (this.sortRechargeColumn === column) {
            this.sortRechargeDirection = this.sortRechargeDirection === 'asc' ? 'desc' : 'asc';
        } else {
            this.sortRechargeColumn = column;
            this.sortRechargeDirection = 'asc';
        }

        this.dashboardData.ricariche_table.sort((a: any, b: any) => {
            const valueA = this.getRechargeValueForSorting(a, column);
            const valueB = this.getRechargeValueForSorting(b, column);

            if (valueA < valueB) {
                return this.sortRechargeDirection === 'asc' ? -1 : 1;
            } else if (valueA > valueB) {
                return this.sortRechargeDirection === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }

    getRechargeValueForSorting(item: any, column: string) {
        switch (column) {
            case 'vodafone':
                return item.vodafone.total_count;
            case 'windtre':
                return item.windtre.total_count;
            case 'kena':
                return item.kena.total_count;
            case 'tim':
                return item.tim.total_count;
            case 'iliad':
                return item.iliad.total_count;
            case 'fast':
                return item.fast.total_count;
            case 'altro':
                return item.altro.total_count;
            case 'total_count':
                return item.total_count;
            case 'total_amount':
                return item.total_amount;
            default:
                return '';
        }
    }

    getRechargeValue(item: any, column: string) {
        switch (column) {
            case 'vodafone':
                return item.vodafone.total_count;
            case 'windtre':
                return item.windtre.total_count;
            case 'kena':
                return item.kena.total_count;
            case 'tim':
                return item.tim.total_count;
            case 'iliad':
                return item.iliad.total_count;
            case 'fast':
                return item.fast.total_count;
            case 'altro':
                return item.altro.total_count;
            case 'total_count':
                return item.total_count;
            case 'total_amount':
                return item.total_amount;
            default:
                return '';
        }
    }

    sortCompensationData(column: string) {
        if (this.sortCompensationColumn === column) {
            this.sortCompensationDirection = this.sortCompensationDirection === 'asc' ? 'desc' : 'asc';
        } else {
            this.sortCompensationColumn = column;
            this.sortCompensationDirection = 'asc';
        }

        this.dashboardData.plafond.dealers.sort((a: any, b: any) => {
            const valueA = this.getCompensationValue(a, column);
            const valueB = this.getCompensationValue(b, column);

            if (valueA < valueB) {
                return this.sortCompensationDirection === 'asc' ? -1 : 1;
            } else if (valueA > valueB) {
                return this.sortCompensationDirection === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }

    getCompensationValue(item: any, column: string) {
        switch (column) {
            case 'accessori_smartphone':
                return item.data.accessori_smartphone;
            case 'plafond_kena':
                return item.data.plafond_kena;
            case 'plafond_wallet':
                return item.data.plafond_wallet;
            case 'total':
                return item.total;
            default:
                return '';
        }
    }

    getTotal(column: string) {
        if (this.dashboardData) {
            let total_ricariche = 0;
            if (this.dashboardData && this.dashboardData.ricariche_table) {
                this.dashboardData.ricariche_table.forEach((item) => {
                    total_ricariche += this.getRechargeValue(item, column);
                });
                return total_ricariche;
            }
        }
    }

    openVisiteDetailModal(item: VisiteDashboard) {
        this.selectedVisit = item;
        this.visitDetailModalTitle = 'Dettaglio visite di: ' + this.selectedVisit.agent_name;
        this.modalService.open(this.visiteDetailModal, { size: 'xl' });
    }

    openKdCubeExpiringModal() {
        this.modalService.open(this.kdCubeExpiringModal, { size: 'xl' });
    }

    openKdCubeExpiredModal() {
        this.modalService.open(this.kdCubeExpiredModal, { size: 'xl' });
    }

    openKdCubePromoModal() {
        this.modalService.open(this.kdCubePromoModal, { size: 'xl' });
    }

    openKdCubeAffiliatiModal() {
        this.modalService.open(this.kdCubeAffiliatiModal, { size: 'xl' });
    }

    goToCompensationTable() {
        document.getElementById('compensationTable').scrollIntoView({
            behavior: 'smooth',
        });
    }

    goToRechargeTable() {
        document.getElementById('rechargeTable').scrollIntoView({
            behavior: 'smooth',
        });
    }

    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }
}
